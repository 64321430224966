<template lang="pug">
    footer
        cookie-law(theme='dark-lime', :buttonText="trn.t('cookies_ok')")
            div(slot='message')
                | {{ trn.t('cookies_msg')}}
</template>

<script>
    import CookieLaw from 'vue-cookie-law'
    import I18n from '../components/i18n.vue.erb'

    export default {
        components: {CookieLaw},
        data() {
            return {
                trn: I18n
            }
        }
    }
</script>