import Vue from 'vue/dist/vue.esm'

// import Vue Globals


// import parent components
import Cookie from '../components/Cookie'

// import library.js - custom functions and methods
import './library'

document.addEventListener('DOMContentLoaded', () => {
    let element = document.getElementById("cookie")


    const app = new Vue({
        el: element,
        data: {

        },
        template: '<Cookie />',
        components: {Cookie},
    })
})
